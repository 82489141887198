import * as React from 'react';
import PropTypes from 'prop-types';
import { ListButton } from 'react-admin';
import { TopToolbar } from '../../components/TopToolbar';

export const OrderActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

OrderActions.propTypes = {
  basePath: PropTypes.any.isRequired,
};

export default OrderActions;
