import React from 'react';
import { Loading } from 'react-admin';

import './Loader.scss';

const Loader = () => (
  <Loading className="Loader" loadingPrimary="Loading..." loadingSecondary="Please wait" />
);

export default Loader;
