import React from 'react';
import { Layout } from 'react-admin';
import { useSelector } from 'react-redux';

import Menu from 'components/Menu';
import CustomAppBar from 'components/AppBar';
import { lightTheme, darkTheme } from './themes';

const MainLayout = (props) => {
  const theme = useSelector((state) => state.theme === 'light' ? lightTheme : darkTheme);

  return (
    <Layout
      {...props}
      menu={Menu}
      appBar={CustomAppBar}
      theme={theme}
    />
  );
};

export default MainLayout;
