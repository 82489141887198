import { makeStyles } from '@material-ui/core/styles';

export const userHasPermission = (permissions, allowedRoles) => {
  let isHasAccess = false;

  allowedRoles.map((item) => {
    if (permissions.includes(item)) {
      isHasAccess = true;
    }

    return false;
  });

  return isHasAccess;
};

export const getItemById = (list, id) => list.find((item) => item.id === id);

export const getChoicesList = (list) => {
  const choicesList = [];

  list.map(({ key, title }) => choicesList.push({ id: key, name: title }));

  return choicesList;
};

export const getProductTrialChoices = () => {
  const trialList = [];

  for (let i = 0; i <= 30; i++) {
    trialList.push({ id: i, name: i.toString() });
  }

  return trialList;
};

export const getDefaultRowStyle = (serverConstants) => ({ project_id }) => {
  const project = getItemById(serverConstants.projectsList, project_id);

  return {
    borderLeftColor: project.color,
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
  };
};

export const getDefaultDataGripStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'transparent',
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
  },
});

export const toTitleCase = (str) => str.replace('_', ' ').replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export const dateToStr = (date) => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${ y }-${ m <= 9 ? `0${ m}` : m }-${ d <= 9 ? `0${ d}` : d}`;
};
export const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
