import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { ShowButton, ListButton, EditButton, useNotify } from 'react-admin';
import { TopToolbar } from '../../components/TopToolbar';
import { CreateUserInApp, handleResetPassword, SendDeepLink, SendWebCabinetLogin } from './helpers';
import { PROJECT_WITH_WEB_CABINET_LOGIN } from '../../providers/ServerConstantsProvider/constants';
import ProjectManager from '../../utils/ProjectManager';

export const UserActions = ({ basePath, data, isEdit, loading }) => {
  const notify = useNotify();

  return (
    <TopToolbar>
      <If condition={PROJECT_WITH_WEB_CABINET_LOGIN.includes(ProjectManager.getCurrentProject())}>
        <Button
          color="primary"
          onClick={() => SendWebCabinetLogin(
          data,
          () => notify('Instruction for web cabinet login will be sent to the user.', 'success'),
          () => notify('Something went wrong.', 'warning'),
        )}
        >Send Web log in
        </Button>
      </If>
      <Button
        color="primary"
        onClick={() => SendDeepLink(
          data,
          () => notify('DeepLink will be sent to the user.', 'success'),
          () => notify('Something went wrong.', 'warning'),
        )}
      >Send DeepLink
      </Button>
      {isEdit ? <ShowButton basePath={basePath} record={data} /> : <EditButton basePath={basePath} record={data} />}
      <ListButton basePath={basePath} />
      <Button
        disabled={(data && data.error_message) || loading}
        color="primary"
        onClick={() => handleResetPassword(
          data,
          () => notify('Password successfully restored.', 'success'),
          () => notify('Something went wrong.', 'warning'),
        )}
      >Reset password
      </Button>
      <Button
        disabled={!(data && data.error_message) || loading}
        color="primary"
        onClick={() => CreateUserInApp(
          data,
          () => notify('User successfully created.', 'success'),
          () => notify('Something went wrong.', 'warning'),
        )}
      >Create App User
      </Button>
    </TopToolbar>
  );
};

UserActions.propTypes = {
  basePath: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  isEdit: PropTypes.any.isRequired,
  loading: PropTypes.any.isRequired,
};

export default UserActions;
