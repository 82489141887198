import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

const EmptyTable = () => (
  <TableHead>
    <TableRow>
      <TableCell />
      <TableCell>No items found. Fill the filters or change their values.</TableCell>
    </TableRow>
  </TableHead>
);

export default EmptyTable;
