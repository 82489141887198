import { Auth } from 'aws-amplify';
import { toTitleCase } from 'utils/common';
import ProjectManager from '../../utils/ProjectManager';

const defaultOptions = {
  authGroups: [],
};

class AuthProvider {
  constructor(options) {
    const optionsBag = { ...defaultOptions, ...options };

    this.authGroups = optionsBag.authGroups;
  }

  login = ({ username, password, clientMetadata }) => Auth.signIn(username, password, clientMetadata);

  checkError = () => Promise.resolve();

  checkAuth = async () => {
    await Auth.currentAuthenticatedUser().then((user) => {
      this.authGroups = new Set(user.signInUserSession.accessToken.payload['cognito:groups']);
      ProjectManager.setCurrentProject(ProjectManager.getCurrentProject() || this.getProjects()[0]);
    }).catch(() => {
      Auth.federatedSignIn();
    });
  };

  logout = () => Auth.signOut();

  getPermissions = async () => {
    const session = await Auth.currentSession();
    const groups = session.getAccessToken().decodePayload()['cognito:groups'];
    localStorage.setItem('groups', JSON.stringify(groups));
    return groups ? Promise.resolve(groups) : Promise.reject();
  };

  getProjects = () => {
    const prefix = 'project_';
    const projects = [];

    this.authGroups.forEach((permission) => {
      if (permission.startsWith(prefix)) {
        projects.push(permission.replace(prefix, '').toLowerCase());
      }
    });

    return projects;
  };

  getIdentity = async () => {
    const session = await Auth.currentSession();
    const userFullName = session.getIdToken().decodePayload().email.split('@')[0].split('.');

    return Promise.resolve({
      fullName: `${toTitleCase(userFullName[0])} ${toTitleCase(userFullName[1])}`,
    });
  };

  getEmail = async () => {
    const session = await Auth.currentSession();
    const userEmail = session.getIdToken().decodePayload().email;

    return { email: userEmail };
  };
}

const authProvider = new AuthProvider();

export default authProvider;
