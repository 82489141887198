import React from 'react';
import {
  useEditController,
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  BooleanField,
} from 'react-admin';

import { UserActions } from './UserActions';
import EditToolbar from '../../components/EditToolbar';
import ReceiptForm from '../../components/ReceiptForm';
import Loader from '../../components/Loader';

export const UserEdit = (props) => {
  const { loading, record } = useEditController(props);
  const blockForm = !!(!loading && !['web', 'WEB', null, undefined, 3].includes(record.payment_platform));

  return (
    <Choose>
      <When condition={!loading}>
        <Edit actions={<UserActions isEdit loading={blockForm} />} {...props} blockForm={blockForm}>
          <SimpleForm toolbar={<EditToolbar blockForm={blockForm} />}>
            <If condition={!loading && record.error_message}>
              <h2 style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                App database error: user not found
              </h2>
            </If>
            <If condition={blockForm}>
              <h2 style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                The user no longer processes on the WEB platform
              </h2>
            </If>
            <BooleanField label="Soft deleted" source="is_suspended" />
            <BooleanInput label="Is paid" source="is_paid" disabled={blockForm} />
            <BooleanField
              label="Lifetime"
              source="lifetime"
              disabled={blockForm}
            />
            <TextInput
              label="lifetime date"
              source="lifetime_timestamp"
              type="text"
              disabled
              format={(value) => value ? new Date(value).toISOString().substring(0, 10) : null}
            />
            <TextInput
              label="lifetime expired at"
              source="lifetime_expired_at"
              type="text"
              disabled
              format={(value) => value ? new Date(value).toISOString().substring(0, 10) : null}
            />

            <TextInput label="Email" source="email" type="email" disabled={blockForm} />
            <BooleanField label="Is password set:" source="is_password" />
            <TextInput label="Payment platform " source="payment_platform" disabled />
            <If condition={!loading && record.orders}>
              <ReceiptForm orders={record.orders} />
            </If>
          </SimpleForm>
        </Edit>
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

export default UserEdit;
