import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import CompareIcon from '@material-ui/icons/Compare';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HistoryIcon from '@material-ui/icons/History';
import BurstModeIcon from '@material-ui/icons/BurstMode';

// custom providers (connect with amplify-aws and react-admin)
import dataProvider from 'providers/DataProvider';
import authProvider from 'providers/AuthProvider';

// providers for getting common data
import ServerConstantsProvider from 'providers/ServerConstantsProvider';

// custom layout
import MainLayout from 'layouts/MainLayout';

// routes
import Users, { UserShow, UserEdit } from 'routes/Users';
import Orders, { OrderShow } from 'routes/Orders';
import Reconciliations, { Snapshots } from 'routes/Reconcilations';
import Aux from './Aux';

const App = () => {
    const allowViewUsersGroups = ['support', 'admin'];
    const [showUsers, setShowUsers] = useState(false);

    useEffect(() => {
      const fetchPermissions = async () => {
        try {
          const fetchedGroups = await authProvider.getPermissions();
          setShowUsers(fetchedGroups.some((el) => new Set(allowViewUsersGroups).has(el)));
        } catch (err) {
          setShowUsers(false);
          console.error(err);
        }
      };
      fetchPermissions();
    }, []);

    return (
      <ServerConstantsProvider>
        <Aux>
          <Admin
            layout={MainLayout}
            authProvider={authProvider}
            dataProvider={dataProvider}
          >
            <Resource
              name="orders"
              options={{ label: 'Orders' }}
              list={Orders}
              show={OrderShow}
              icon={<MonetizationOnIcon />}
            />

            {showUsers ? (
              <Resource
                name="users"
                options={{ label: 'Users' }}
                list={Users}
                show={UserShow}
                edit={UserEdit}
                icon={<CompareIcon />}
              />
          ) : (<span />)}
            <Resource
              name="snapshot"
              options={{ label: 'Snapshots' }}
              list={Snapshots}
              icon={<BurstModeIcon />}
            />
            <Resource
              name="reconciliation"
              options={{ label: 'Reconciliation' }}
              list={Reconciliations}
              icon={<HistoryIcon />}
            />

          </Admin>
        </Aux>
      </ServerConstantsProvider>
    );
};

export default App;
