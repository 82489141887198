import authProvider from 'providers/AuthProvider';

class OrdersExportNormalizer {
  static requiredExportFields = [
    'date_from',
    'date_to',
    'format',
  ];

  static optionalFields = [
    'country',
    'descriptor',
    'email',
    'external_id',
    'lifetime',
  ];

  static async normalize(params) {
    const normalized = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key of OrdersExportNormalizer.requiredExportFields) {
      if (!(key in params)) {
        throw new Error(`${key.replace('_', ' ')} is required.`);
      }
      normalized[key] = params[key];
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of OrdersExportNormalizer.optionalFields) {
      if (key in params) {
        normalized[key] = params[key];
      }
    }
    const { email } = await authProvider.getEmail();
    // eslint-disable-next-line camelcase
    normalized.mail_to = email;

    return normalized;
  }
}

export default OrdersExportNormalizer;
