import * as React from 'react';
import { cloneElement, useState, useEffect } from 'react';
import {
  useListContext,
  useDataProvider,
  useNotify,
  TopToolbar,
  Button,
  sanitizeListRestProps,
  } from 'react-admin';
import ReceiptIcon from '@material-ui/icons/Receipt';
import OrdersExportNormalizer from '../../normalizers/OrdersExportNormalizer';
import { ADMIN, FINANCE } from '../../constants/permissionsRole';
import authProvider from '../../providers/AuthProvider';

const OrdersListActions = () => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    filters,
    className,
    exporter,
    maxResults,
    ...rest
  } = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [exportReceiptButtonDisabled, setExportReceiptButtonDisabled] = useState(false);
  const [exportCsvButtonDisabled, setExportCsvButtonDisabled] = useState(false);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const fetchedGroups = await authProvider.getPermissions();
        setGroups(fetchedGroups);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPermissions();
  }, []);

  const handleExportRequest = async (format, buttonCallback) => {
    try {
      buttonCallback(true);
      const filtersCopy = {
        format,
        ...filterValues,
      };
      const bodyParams = await OrdersExportNormalizer.normalize(filtersCopy);

      dataProvider
        .export('orders', bodyParams)
        .then(() => {
          notify(
            'File is preparing! You will receive an email after the process finished.',
            'success',
          );
        })
        .catch((error) => {
          console.error(error);
          notify(error.response.data?.error || 'Something went wrong', 'warning');
        })
        .finally(() => {
          buttonCallback(false);
        });
    } catch (e) {
      buttonCallback(false);
      notify(e.toString(), 'warning');
    }
  };

  const exportCsv = async () => {
    await handleExportRequest('csv', setExportCsvButtonDisabled);
  };

  const exportArchive = async () => {
    await handleExportRequest('zip', setExportReceiptButtonDisabled);
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <If condition={Object.values(groups).some((group) => [FINANCE, ADMIN].includes(group))}>
        <Button
          onClick={() => { exportArchive(); }}
          label="Export receipts"
          disabled={exportReceiptButtonDisabled}
        >
          <ReceiptIcon />
        </Button>
        <Button
          onClick={() => { exportCsv(); }}
          label="Export CSV"
          disabled={exportCsvButtonDisabled}
        >
          <ReceiptIcon />
        </Button>
      </If>

    </TopToolbar>
  );
};

export default OrdersListActions;
