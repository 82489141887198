import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withServerConstants } from './providers/ServerConstantsProvider';

class Aux extends Component {
  render() {
    const { children, serverConstants } = this.props;
    if (serverConstants.updateProject) {
          serverConstants.setUpdateProject(false);
    }
    return (
      <div key={serverConstants.updateProject}>
        { children }
      </div>
    );
  }
}
Aux.propTypes = {
children: PropTypes.node.isRequired,
serverConstants: PropTypes.shape.isRequired,
};
export default compose(withServerConstants)(Aux);
