import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItemLink,
  getResources, SelectInput, required, SimpleForm,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import ProjectManager from '../../utils/ProjectManager';
import { withServerConstants } from '../../providers/ServerConstantsProvider';

const Menu = ({ onMenuClick, serverConstants }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const projectList = serverConstants.projectsList ? serverConstants.projectsList : [{ name: '' }];
  const onSubmit = (e) => {
    ProjectManager.setCurrentProject(e.target.value);
      serverConstants.setUpdateProject(true);
  };

  return (
    <Box mt={1}>
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={resource?.options?.label}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          leftIcon={resource.icon}
        />
      ))}
      <SimpleForm
        save={onSubmit}
        onSubmit={() => {}}
        initialValues={{ id: ProjectManager.getCurrentProject() }}
        toolbar={false}
      >
        <SelectInput
          style={{ width: '140%' }}
          source="id"
          label="Please select the project"
          onChange={onSubmit}
          choices={projectList}
          validate={[required('Project field is required')]}
          resettable
        />
      </SimpleForm>
    </Box>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  serverConstants: PropTypes.object.isRequired,

};

Menu.defaultProps = {
  onMenuClick: () => {},
};

export default compose(withServerConstants)(Menu);
