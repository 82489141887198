import React from 'react';
import { Show, SimpleShowLayout, DateField, TextField } from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { OrderActions } from './OrderActions';

export const OrderShow = (props) => (
  <Show actions={<OrderActions />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField label="Amount" source="amount" />
      <TextField label="Currency" source="currency" />
      <TextField label="External Id" source="external_id" />
      <TextField label="External Status" source="external_status" />
      <TextField label="Status" source="status" />
      <TextField label="Is Sandbox" source="is_sandbox" />
      <TextField label="Parent Id" source="parent_id" />
      <TextField label="Client Ip" source="client_ip" />
      <JsonField
        source="payment_data"
        label="Payment Data"
        addLabel
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <TextField label="Payment Method" source="payment_method" />
      <TextField label="Subscription External Id" source="subscription_id" />
      <DateField label="Created At" source="created_at" />
      <DateField label="Updated At" source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default OrderShow;
