import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Filter,
  DateTimeInput,
  Pagination,
  FunctionField,
  SelectInput,
} from 'react-admin';
import List from 'components/List';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import Loader from 'components/Loader';
import { getDefaultDataGripStyles } from '../../utils/common';
import { EmptyTable } from '../../components/EmptyTable';
import { PROCESSORS } from '../../constants/common';

const rowStyle = (record) => ({
  borderLeftColor: record.color,
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
});

const ReconciliationFilter = (props) => (
  <Filter {...props} style={{ marginTop: '5px', marginBottom: '10px' }}>
    <DateTimeInput
      label="Create from"
      source="created_from"
      resettable
      alwaysOn
    />
    <DateTimeInput
      label="Create to"
      source="created_to"
      resettable
      alwaysOn
    />
    <SelectInput
      label="Payment Gateway"
      source="payment_provider"
      resettable
      alwaysOn
      choices={PROCESSORS}
    />
  </Filter>
);

const Snapshots = (props) => {
  const { serverConstants } = props;
  const classes = getDefaultDataGripStyles();

  return (
    <Choose>
      <When condition={serverConstants?.countries}>
        <List
          rowStyle={rowStyle}
          dataGridClasses={{ headerRow: classes.headerRow }}
          filters={<ReconciliationFilter />}
          pagination={<Pagination {...props} limit={<EmptyTable />} />}
          empty={false}
          exporter={false}
          {...props}
        >
          <FunctionField
            label="Snapshot"
            render={(record) => `${record.from_time.replace('T', ' ')} - ${record.to_time.replace('T', ' ')}`}
          />;
          <TextField label="Matched" source="matched" />
          <TextField label="Unmatched" source="unmatched" />
          <TextField label="Missed" source="missed" />
          <TextField label="Total Extracted" source="total_extracted" />
        </List>
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

Snapshots.propTypes = {
  serverConstants: PropTypes.object,
};

Snapshots.defaultProps = {
  serverConstants: {},
};

export default withServerConstants(Snapshots);
