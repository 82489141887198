import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  SelectInput,
  required,
} from 'react-admin';
import Modal from 'components/Modal';

import './SelectProjectModal.scss';
import { withServerConstants } from '../../providers/ServerConstantsProvider';
import CustomToolbar from '../components/CustomToolbar';
import ProjectManager from '../../utils/ProjectManager';

const SelectProjectModal = ({ isOpen, onCloseModal, serverConstants }) => {
  const onSubmit = (project) => {
    ProjectManager.setCurrentProject(project.id);
    onCloseModal(false);
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onCloseModal={() => onCloseModal(false)}
      modalTitle="Select project"
    >
      <SimpleForm
        save={onSubmit}
        onSubmit={() => {}}
        initialValues={{ id: ProjectManager.getCurrentProject() }}
        toolbar={CustomToolbar()}
      >
        <SelectInput
          source="id"
          label="Please select the project"
          choices={serverConstants.projectsList}
          validate={[required('Project field is required')]}
          resettable
        />
      </SimpleForm>
    </Modal>
  );
};

SelectProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  serverConstants: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default compose(withServerConstants)(SelectProjectModal);
