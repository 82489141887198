import * as React from 'react';
import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import GradeIcon from '@material-ui/icons/Grade';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import './AppBar.scss';
import { Box } from '@material-ui/core';
import SelectProjectModal from '../../modals/SelectProjectModal';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const ConfigurationMenu = forwardRef((props, ref) => (
  <MenuItemLink
    ref={ref}
    to="/settings"
    primaryText="Personal Setting"
    leftIcon={<SettingsIcon />}
    onClick={props.onClick}
    sidebarIsOpen
  />
));

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Box onClick={(event) => event.preventDefault()}>
      <MenuItemLink
        to="#"
        primaryText="Select Project"
        leftIcon={<GradeIcon />}
        onClick={props.onClickProjectMenuItem}
      />
    </Box>
  </UserMenu>
);

const CustomAppBar = (props) => {
  const classes = useStyles();
  const [isOpenProjectModal, toggleOpenProjectModal] = useState(false);
  const currentProject = localStorage.getItem('current_project');
  const getProjectColor = (project) => {
    switch (project) {
      case 'menscoach': return '#1847df';
      case 'mycoach': return '#49D461';
      case 'fasteasy': return '#f09f8f';
      case 'yoga': return '#FCC419';
      case 'calorie-tracker': return '#6473AA';
      case 'femia': return '#4C61C7';
      case 'mindea': return '#ED8B76';
      default: return '#1847df';
    }
  };

  return (
    <AppBar
      style={{ backgroundColor: getProjectColor(currentProject) }}
      {...props}
      elevation={1}
      userMenu={<CustomUserMenu onClickProjectMenuItem={() => toggleOpenProjectModal(true)} />}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <h3>{currentProject}</h3>
      <span className={classes.spacer} />
      <SelectProjectModal
        onCloseModal={(modalState) => toggleOpenProjectModal(modalState)}
        isOpen={isOpenProjectModal}
      />
    </AppBar>
  );
};

ConfigurationMenu.propTypes = {
  onClick: PropTypes.any.isRequired,
};

CustomUserMenu.propTypes = {
  onClickProjectMenuItem: PropTypes.any.isRequired,

};

export default CustomAppBar;
