import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { countries } from './constants';

const ServerConstantsContext = React.createContext([]);

class serverConstantsProvider extends PureComponent {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
    projectsList: null,
    info: countries,
    updateProject: false,
  };
}

  async componentDidMount() {
    await this.loadingProjects();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadingProjects = async () => {
    const projectsList = await API.get('admin', '/projects', null);
    if (this._isMounted) this.setState({ projectsList });
  };

  setUpdateProject = (value) => {
    this.setState({ updateProject: value });
  }

  render() {
    const {
      projectsList,
      info,
      updateProject,
    } = this.state;

    const providerValue = {
      loadingProjects: () => this.loadingProjects(),
      setUpdateProject: (value) => this.setUpdateProject(value),
      projectsList,
      ...info,
      updateProject,
    };

    return (
      <ServerConstantsContext.Provider value={providerValue}>
        {this.props.children}
      </ServerConstantsContext.Provider>
    );
  }
}

export const ServerConstantsConsumer = ServerConstantsContext.Consumer;

serverConstantsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default serverConstantsProvider;
