import React from 'react';
import {
  TextField,
  BooleanField,
  EditButton,
  DateField,
  TextInput,
  Filter,
  FunctionField,
  Pagination, useNotify,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import List from 'components/List';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { getDefaultDataGripStyles } from '../../utils/common';
import { EmptyTable } from '../../components/EmptyTable';
import { SendDeepLink, SendWebCabinetLogin } from './helpers';
import { PROJECT_WITH_WEB_CABINET_LOGIN, PROJECTS_WITH_PUBLIC_ID_FILTER } from
'../../providers/ServerConstantsProvider/constants';
import ProjectManager from '../../utils/ProjectManager';

const rowStyle = (record) => ({
  borderLeftColor: record.color,
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
});

const number = (value) => value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;

const UserFilter = (props) => (
  <Filter {...props} style={{ marginTop: '5px', marginBottom: '10px' }}>
    <TextInput source="email" resettable alwaysOn />
    <TextInput source="id" resettable alwaysOn />
    <TextInput label="App id" source="core_id" resettable alwaysOn validate={[number]} />
    <If condition={PROJECTS_WITH_PUBLIC_ID_FILTER.includes(ProjectManager.getCurrentProject())}>
      <TextInput label="Public id" source="public_id" resettable alwaysOn />
    </If>
  </Filter>
);

const Users = (props) => {
  const classes = getDefaultDataGripStyles();
  const notify = useNotify();

  return (
    <List
      rowStyle={rowStyle}
      dataGridClasses={{ headerRow: classes.headerRow }}
      exporter={false}
      empty={false}
      pagination={<Pagination {...props} limit={<EmptyTable />} />}
      filters={<UserFilter />}
      {...props}
    >
      <TextField label="Id" source="id" />
      <TextField label="App Id" source="core_id" />
      <TextField label="Email" source="email" />
      <TextField label="Name" source="name" />
      <TextField label="Surname" source="surname" />
      <DateField label="Create date" source="created_at" />
      <DateField label="Update date" source="updated_at" />
      <BooleanField label="Is paid" source="is_paid" />
      <BooleanField label="Soft deleted" source="is_suspended" />
      <TextField label="Country" source="country" />
      <BooleanField label="Is password set" source="is_password" />
      <If condition={PROJECT_WITH_WEB_CABINET_LOGIN.includes(ProjectManager.getCurrentProject())}>
        <FunctionField
          render={(user) => (
            <Button
              color="primary"
              onClick={() => SendWebCabinetLogin(
                user,
                () => notify('Instruction for web cabinet login will be sent to the user..'),
                () => notify('Something went wrong.'),
              )}
            >Send Web log in
            </Button>
          )}
        />
      </If>
      <FunctionField
        render={(user) => (
          <Button
            color="primary"
            onClick={() => SendDeepLink(
              user,
              () => notify('DeepLink will be sent to the user.'),
              () => notify('Something went wrong.'),
            )}
          >Send DeepLink
          </Button>
        )}
      />
      <EditButton />
      <FunctionField
        render={(user) => (
          <Button
            color="primary"
            startIcon={<LocalAtmIcon />}
            component={Link}
            to={{
              pathname: '/orders',
              search: `filter=${encodeURIComponent(JSON.stringify({ email: user.email }))}`,
            }}
          >
            Orders
          </Button>
        )}
      />

    </List>
  );
};
export default Users;
