import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  TextInput,
  Filter,
  DateTimeInput,
  Pagination,
  // FunctionField,
  SelectInput,
  List,
  DateField,
  useRecordContext,
} from 'react-admin';
// import List from 'components/List';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import Loader from 'components/Loader';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { getDefaultDataGripStyles } from '../../utils/common';
import { EmptyTable } from '../../components/EmptyTable';
import { DB_STATUS, PG_STATUS, PROCESSORS, RECONCILIATION_REASON, RECONCILIATION_STATUS } from '../../constants/common';

const rowStyle = (record) => ({
  borderLeftColor: record.color,
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
});

const ReconciliationFilter = (props) => (
  <Filter {...props} style={{ marginTop: '5px', marginBottom: '10px' }}>
    <TextInput label="Prev Rec Status" source="prev_rec_status" resettable alwaysOn disabled />
    <TextInput label="Prev Rec Reason" source="prev_rec_reason" resettable alwaysOn disabled />
    <TextInput label="Updated from" source="updated_from" resettable alwaysOn disabled />
    <TextInput label="Updated to" source="updated_to" resettable alwaysOn disabled />
    <TextInput label="Updated Reason" source="updated_reason" resettable alwaysOn disabled />

    <TextInput label="Order id" source="order_id" resettable alwaysOn />
    <DateTimeInput label="Create from" source="created_from" options={{ timeZone: 'UTC' }} resettable alwaysOn />
    <DateTimeInput label="Create to" source="created_to" options={{ timeZone: 'UTC' }} resettable alwaysOn />
    <SelectInput
      label="Rec Status"
      source="reconciliation_status"
      resettable
      alwaysOn
      choices={RECONCILIATION_STATUS}
    />
    <SelectInput
      label="Rec Reason"
      source="reconciliation_reason"
      resettable
      alwaysOn
      choices={RECONCILIATION_REASON}
    />
    <SelectInput
      label="PG Status"
      source="payment_provider_status"
      resettable
      alwaysOn
      choices={PG_STATUS}
    />
    <SelectInput
      label="DB Status"
      source="db_status"
      resettable
      alwaysOn
      choices={DB_STATUS}
    />
    <SelectInput
      label="Payment Gateway"
      source="payment_provider"
      resettable
      alwaysOn
      choices={PROCESSORS}
    />
  </Filter>
);

const AddSnapshotField = () => {
    const record = useRecordContext();
    if (!record) return null;
    record.snapshot = `${record.from_time.replace('T', ' ')}  -  ${record.to_time.slice(11, 19)}`;
    return null;
};

const Reconciliation = (props) => {
  const { serverConstants } = props;
  const classes = getDefaultDataGripStyles();

  return (
    <Choose>
      <When condition={serverConstants?.countries}>
        <List
          rowStyle={rowStyle}
          dataGridClasses={{ headerRow: classes.headerRow }}
          exporter={false}
          empty={false}
          pagination={<Pagination {...props} limit={<EmptyTable />} />}
          filters={<ReconciliationFilter />}
          {...props}
        >
          <CustomizableDatagrid>
            <AddSnapshotField />
            <DateField label="Created At" source="created_at" showTime />
            <TextField label="Order Id" source="external_order_id" />
            <TextField label="Order Reconciliation Id" source="id" />
            <DateField label="Updated At" source="updated_at" showTime />
            <TextField label="Reconciliation Reason" source="reason" />
            <TextField label="Payment Gateway" source="payment_provider" />
            <TextField label="Reconciliation Status" source="status" />
            <TextField label="DB Status" source="order_status" />
            <TextField label="PG Status" source="external_status" />
            <TextField label="Amount" source="amount" />
            <TextField label="Currency" source="currency" />
            <TextField label="Payment Method" source="payment_method" />
            <TextField label="Payment Type" source="payment_type" />
            <TextField label="Snapshot" source="snapshot" />
          </CustomizableDatagrid>
        </List>
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  );
};

Reconciliation.propTypes = {
  serverConstants: PropTypes.object,
};

Reconciliation.defaultProps = {
  serverConstants: {},
};

export default withServerConstants(Reconciliation);
