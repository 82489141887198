import React from 'react';
import PropTypes from 'prop-types';
import {
  List as ReactAdminList,
  Datagrid,
} from 'react-admin';

import Pagination from 'components/Pagination';

const List = (props) => {
  const {
    children,
    rowStyle,
    rowClick,
    expand,
    actions,
    dataGridClasses,
    ...rest
  } = props;

  return (
    <ReactAdminList
      perPage={20}
      pagination={<Pagination />}
      bulkActionButtons={false}
      actions={actions}
      {...rest}
    >
      <Datagrid
        rowStyle={rowStyle}
        rowClick={rowClick}
        classes={dataGridClasses}
        expand={expand}
      >
        {children}
      </Datagrid>
    </ReactAdminList>
  );
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  rowStyle: PropTypes.func,
  rowClick: PropTypes.string,
  expand: PropTypes.node,
  actions: PropTypes.node,
  dataGridClasses: PropTypes.object,
};

List.defaultProps = {
  rowStyle: () => {},
  rowClick: null,
  expand: null,
  actions: undefined,
  dataGridClasses: {},
};

export default List;
