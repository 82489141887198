export const PROCESSORS = [
  {
    id: 'Solidgate',
    name: 'Solidgate',
  },
  {
    id: 'Braintree',
    name: 'Braintree',
  },
];

export const RECONCILIATION_STATUS = [
  {
    id: 'matched',
    name: 'Matched',
  },
  {
    id: 'unmatched',
    name: 'Unmatched',
  },
  {
    id: 'missed',
    name: 'Missed',
  },
];

export const RECONCILIATION_REASON = [
  {
    id: 'All matched',
    name: 'All matched',
  },
  {
    id: 'status not matched',
    name: 'Status not matched',
  },
  {
    id: 'amount not matched',
    name: 'Amount not matched',
  },
  {
    id: 'currency not matched',
    name: 'Currency not matched',
  },
  {
    id: 'status, amount not matched',
    name: 'Status, amount not matched',
  },
  {
    id: 'status, amount, currency not matched',
    name: 'Status, amount, currency not matched',
  },
  {
    id: 'status, currency not matched',
    name: 'Status, currency not matched',
  },
  {
    id: 'amount, currency not matched',
    name: 'Amount, currency not matched',
  },
  {
    id: 'Order data is missed',
    name: 'Order data is missed',
  },
];

export const DB_STATUS = [
  {
    id: 1,
    name: 'Approved',
  },
  {
    id: 2,
    name: 'Declined',
  },
  {
    id: 4,
    name: 'Сhargeback',
  },
  {
    id: 3,
    name: 'Refunded',
  },
];

export const PG_STATUS = [
  {
    id: 'created',
    name: 'Created',
  },
  {
    id: 'processing',
    name: 'Processing',
  },
  {
    id: '3ds_verify',
    name: '3ds_verify',
  },
  {
    id: 'settle_pending',
    name: 'Settle_pending',
  },
  {
    id: 'approved',
    name: 'Approved',
  },
  {
    id: 'declined',
    name: 'Declined',
  },
  {
    id: 'refunded',
    name: 'Refunded',
  },
  {
    id: 'auth_ok',
    name: 'Auth_ok',
  },
  {
    id: 'auth_failed',
    name: 'Auth_failed',
  },
  {
    id: 'settle_ok',
    name: 'Settle_ok',
  },
  {
    id: 'partial_settled',
    name: 'Partial_settled',
  },
  {
    id: 'void_ok',
    name: 'Void_ok',
  },

];
