import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar,
  useNotify,
  useRedirect,
  SaveButton,
  Confirm,
} from 'react-admin';
import dataProvider from 'providers/DataProvider';
import { Button, CircularProgress } from '@material-ui/core';
import { Delete, Loyalty } from '@material-ui/icons';
import { sleep } from '../../utils/common';
import authProvider from '../../providers/AuthProvider';

const DeleteUserButton = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [finalSaving, setFinalSaving] = useState(true);
  const notify = useNotify();
  const redirect = useRedirect();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    try {
      setOpen(false);
      setFinalSaving(false);

      const response = await dataProvider.delete('users', { id: record.id });
      setFinalSaving(true);

      if (Object.keys(response.data).length === 0 || 'message' in response.data) {
        // TODO response data format differs from project to project
        alert('User deleted successfully!');
        sleep(200).then(() => redirect('/users'));
      } else {
        let msg = '';
        msg += `- Deleting user from App DataBase - ${response.data.app_db}\n`;
        msg += `- Deleting user from SendPulse DataBase - ${response.data.sendpulse}\n`;
        msg += `- Deleting user from Web DataBase - ${response.data.web_db}\n`;
        alert(msg);

        if (response.data.web_db) {
          sleep(200).then(() => redirect('/users'));
        } else {
          notify('The user has not been deleted from WEB DataBase', 'warning');
        }
      }
    } catch (e) {
      notify('Something went wrong.', 'warning');
    }
    setFinalSaving(true);
  };

  return (
    <>
      <Button
        color="secondary"
        style={{ marginLeft: '20px', color: record.is_suspended ? 'gray' : 'red' }}
        onClick={handleClick}
        startIcon={<Delete />}
        disabled={record.is_suspended}
      >{record.is_suspended ? 'Already deleted' : 'DELETE'}
        <If condition={!finalSaving}>
          <CircularProgress size={18} thickness={2} />
        </If>
        <Confirm
          isOpen={open}
          title={`Delete user ${record.id}`}
          content="After confirming you will not be able to restore the user. Are you sure?"
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </Button>
    </>
  );
};

const UpdateUserLifetime = ({ props }) => {
  const [open, setOpen] = useState(false);
  const [finalSaving, setFinalSaving] = useState(true);
  const notify = useNotify();
  const redirect = useRedirect();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    const userData = props.record;
    userData.lifetime = !props.record.lifetime;

    try {
      setOpen(false);
      setFinalSaving(false);
      const response = await dataProvider.update('users', { id: userData.id, data: userData });
      setFinalSaving(true);

      if (Object.keys(response.data)) {
        notify('User updated successfully!', 'success');
        sleep(200).then(() => redirect('/users'));
      }
    } catch (e) {
      notify(`Something went wrong. ${e}`, 'warning');
    }
    setFinalSaving(true);
  };

  return (
    <>
      <Button
        color="secondary"
        style={{ marginLeft: '20px' }}
        onClick={handleClick}
        startIcon={<Loyalty />}
      >{props.record.lifetime ? 'Revoke' : 'Give'} lifetime
        <If condition={!finalSaving}>
          <CircularProgress size={18} thickness={2} />
        </If>
        <Confirm
          isOpen={open}
          title="Update Lifetime status"
          content={(
            <>{props.record.lifetime ? 'After pressing ‘Confirm’ user lifetime will be revoke' : (
              <>
                <><b>Attention</b>, on YGw, MBw, FCw, FEw, Omo only: <br />
                  Do you want to give an additional 1-year subscription to the user?
                  <br />
                  <b>Important:</b> After pressing ‘Confirm’ user subscriptions will be canceled automatically
                </>
                <p>Other projects: <br />
                  lifetime works like earlier
                </p>
              </>
            )}
            </>

          )}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </Button>
    </>
  );
};

// const UpdateUserButton = ({ props }) => {
//   const notify = useNotify();
//   const redirect = useRedirect();
//   // const [finalSaving, setFinalSaving] = useState(true);
//   // const disabled = props.pristine || props?.disabled;
//   // const style = disabled ? { marginLeft: '15px' } : { marginLeft: '15px', color: '#28CDE7' };
//   const handleSubmit = async () => {
//     try {
//       // setFinalSaving(false);
//       const response = await dataProvider.update('users', { id: props.record.id, data: props.record });
//       // setFinalSaving(true);
//       if (Object.keys(response.data)) {
//         notify('User updated successfully!', 'success');
//         sleep(200).then(() => redirect('/users'));
//       }
//     } catch (e) {
//       notify(`Something went wrong. ${e}`, 'warning');
//     }
//     // setFinalSaving(true);
//   };
//
//   return (
//     <>
//       <SaveButton
//         style={{ marginLeft: '15px' }}
//         {...props}
//         disabled={props.pristine || props?.disabled}
//         onSave={(data) => handleSubmit(data)}
//       />
//       {/* <Button */}
//       {/*  style={style} */}
//       {/*  {...props} */}
//       {/*  disabled={disabled} */}
//       {/*  onClick={handleSubmit} */}
//       {/*  startIcon={<Save />} */}
//       {/* >SAVE */}
//       {/*  <If condition={!finalSaving}> */}
//       {/*    <CircularProgress size={18} thickness={2} /> */}
//       {/*  </If> */}
//       {/* </Button> */}
//     </>
//
//   );
// };

const EditToolbar = (props) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const fetchedGroups = await authProvider.getPermissions();
        setGroups(fetchedGroups);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPermissions();
  }, []);

  return (
    <Toolbar elevation={1} {...props}>
      {/* <UpdateUserButton props={props} /> */}
      <SaveButton
        disabled={props.pristine || props?.disabled}
        style={{ marginLeft: '15px' }}
      />
      <UpdateUserLifetime props={props} />
      {Object.values(groups).includes('admin') ? (<DeleteUserButton id={props.record} />) : null}

    </Toolbar>
  );
};

EditToolbar.propTypes = {
  pristine: PropTypes.bool,
  saving: PropTypes.bool,
  disabled: PropTypes.bool,
};

EditToolbar.defaultProps = {
  pristine: false,
  saving: false,
  disabled: false,
};

export default EditToolbar;
