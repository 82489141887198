class ProjectManager {
  static #currentProjectKey = 'current_project';

  static setCurrentProject(project) {
    localStorage.setItem(ProjectManager.#currentProjectKey, project);
  }

  static getCurrentProject() {
    return localStorage.getItem(ProjectManager.#currentProjectKey);
  }
}

export default ProjectManager;
